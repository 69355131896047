import { AssetV2 } from 'types/asset.type';
import { ASSET_ROOT_INPUTS } from 'components/asset-management/constants';
import { AssetFieldValue } from '../types/asset-management-field.type';

const changeInputOnAssetToEdit = (
    key: string,
    value: AssetFieldValue,
    setAssetToEdit: React.Dispatch<React.SetStateAction<AssetV2<unknown, unknown> | null>>,
    setAssetChanged: React.Dispatch<React.SetStateAction<boolean>>
): void => {
    setAssetToEdit((prevAsset) => {
        if (prevAsset) {
            const editedAsset = (() => {
                if (ASSET_ROOT_INPUTS.includes(key)) {
                    return { ...prevAsset, [key]: value as string | string[] | boolean };
                } else {
                    return { ...prevAsset, labels: { ...prevAsset.labels, [key]: value as string[] } };
                }
            })();

            return editedAsset;
        }
        return null;
    });
    setAssetChanged(true);
};

export { changeInputOnAssetToEdit };
