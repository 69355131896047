import { AssetV2 } from 'types/asset.type';
import { StoreTypes } from 'types/store.type';
import ComponentStoreHelpers from 'components/data/ComponentStore';

const findAssetInStore = (assetId: string, storeName: StoreTypes): AssetV2<unknown, unknown> | undefined => {
    const { assetsList, draft, binned, collectionsContent } = ComponentStoreHelpers.get(storeName);
    if (assetsList) {
        const foundAvailableAsset = assetsList.find((asset: AssetV2<unknown, unknown>) => asset._id === assetId);
        if (foundAvailableAsset) return foundAvailableAsset;
    }
    if (draft?.assetsList) {
        const foundDraftAsset = draft.assetsList.find((asset: AssetV2<unknown, unknown>) => asset._id === assetId);
        if (foundDraftAsset) return foundDraftAsset;
    }
    if (binned?.assetsList) {
        const foundBinnedAsset = binned.assetsList.find((asset: AssetV2<unknown, unknown>) => asset._id === assetId);
        if (foundBinnedAsset) return foundBinnedAsset;
    }
    if (collectionsContent) {
        for (const key in collectionsContent) {
            const foundCollectionAsset = collectionsContent[key].assetsList?.find((asset: AssetV2<unknown, unknown>) => asset._id === assetId);
            if (foundCollectionAsset) return foundCollectionAsset;
        }
    }

    return undefined;
};

export { findAssetInStore };
