import { AssetStatus } from 'types/asset.type';
import { Right } from '../types/asset-management-right.type';

// Check if the user can bin the asset based on the asset status and the users rights.
const canBin = (userHasRight: (right: Right) => boolean, status?: AssetStatus): boolean => {
    if (userHasRight('write') && status === 'draft') return true;
    if (userHasRight('management') && status !== 'binned') return true;
    return false;
};

export { canBin };
