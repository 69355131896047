import { useSelector } from 'react-redux';
import Store from 'types/store.type';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import { useAssetManagementConfigContext } from '../context/asset-management-config.context';

const useAMCollectionSelection = (
    collectionId?: string
): {
    isSelected: boolean;
    selectCollection: (collectionId: string) => void;
    setSelectedCollections: (collectionIds: string[]) => void;
} => {
    const { storeName } = useAssetManagementConfigContext();

    const isSelected = useSelector((state: Store) => {
        const componentStore = state.componentStore;
        if (!componentStore || !collectionId || !storeName) {
            return false;
        }

        const assetManagementStore = componentStore[storeName];

        if (!assetManagementStore) {
            return false;
        }

        return assetManagementStore.state.selectedCollectionIds.includes(collectionId);
    });

    const selectCollection = (collectionId: string) => {
        const selectedCollectionIds = ComponentStoreHelpers.get(storeName)?.state.selectedCollectionIds || [];

        if (selectedCollectionIds.includes(collectionId)) {
            ComponentStoreHelpers.setModel(
                storeName,
                'state.selectedCollectionIds',
                selectedCollectionIds.filter((id) => id !== collectionId)
            );
            return;
        }

        ComponentStoreHelpers.setModel(storeName, 'state.selectedCollectionIds', [...selectedCollectionIds, collectionId]);
    };

    const setSelectedCollections = (collectionIds: string[]) => {
        ComponentStoreHelpers.setModel(storeName, 'state.selectedCollectionIds', collectionIds);
    };

    return {
        isSelected,
        selectCollection,
        setSelectedCollections
    };
};

export default useAMCollectionSelection;
