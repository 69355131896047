import { Filters } from 'types/filter.type';

const isHumanFiltered = (assetFilters: Filters): boolean => {
    if (!assetFilters) return false;

    // The stautus filter is set based upon the view, so we don't want to count it as a human set filter.
    delete assetFilters.status;
    return Boolean(Object.keys(assetFilters).length);
};

export { isHumanFiltered };
