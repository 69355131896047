import Translation from 'components/data/Translation';
import AssetEditorStepper from '../../../../AssetEditor/interfaces/AssetEditorStepper';

/**
 * An array of `AssetEditorStepper` objects representing the steps in the magic eraser tool.
 */
const objectRemoverSteps: AssetEditorStepper[] = [
    {
        title: Translation.get('assetGalleryDialog.assetEditor.makeSelection', 'content-space'),
        description: Translation.get('assetGalleryDialog.assetEditor.makeRemoverSelectionDescription', 'content-space')
    },
    {
        title: Translation.get('assetGalleryDialog.assetEditor.reviewResults', 'content-space'),
        description: Translation.get('assetGalleryDialog.assetEditor.reviewResultDescription', 'content-space')
    }
];

export default objectRemoverSteps;
