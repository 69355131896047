import { AssetV2 } from 'types/asset.type';
import { MediaAsset, MediaAssetFile } from 'components/media-management/types/media-management.type';
import Translation from 'components/data/Translation';
import { MediaSubType } from 'components/media-management/types/media-sub-type.type';
import { TemplateAsset } from 'components/template-management/types/template-management.type';
import { getTemplatePreviewImage } from 'components/template-management/utilities';
import { SelectorAssetSourceData } from '../components/selector/types/selector-asset-source-data.type';
import { SelectorAsset } from '../components/selector/types/selector-asset.type';

const getMediaAssetSubtitle = (subType?: MediaSubType, file?: MediaAssetFile): string => {
    if (!subType) return '';
    if (!file) return '';

    switch (subType) {
        case 'image':
            return `${file.width?.toFixed()} x ${file.height?.toFixed()} | ${file.humanSize}`;
        default:
            return `${Translation.get(`sub_types.${subType}`, 'media-management')} | ${file.humanSize}`;
    }
};

const mapAssetToSelectorItem = (asset: AssetV2<unknown, unknown>): SelectorAssetSourceData | SelectorAsset => {
    switch (asset.type) {
        case 'media': {
            const file: MediaAssetFile | undefined = (asset as MediaAsset)?.data?.files?.[0];
            return {
                id: asset._id,
                extension: file?.extension || 'unknown',
                fileType: asset.subType || 'unknown',
                title: asset.title,
                subtitle: getMediaAssetSubtitle(asset?.subType as MediaSubType, file),
                url: file?.url || '',
                size: file?.size ? parseInt(file.size) : 0,
                width: file?.width,
                height: file?.height,
                humanSize: file?.humanSize,
                originalRecord: asset as MediaAsset
            } as SelectorAssetSourceData;
        }
        case 'template':
            return {
                id: asset._id,
                title: asset.title,
                subtitle: Translation.get(`sub_types.${asset.subType}`, 'template-management'),
                thumbnail: getTemplatePreviewImage(asset as TemplateAsset),
                originalRecord: asset as TemplateAsset
            } as SelectorAsset;
        default:
            return {
                id: asset._id,
                title: asset.title,
                originalRecord: asset as AssetV2<unknown, unknown>
            };
    }
};

export { mapAssetToSelectorItem };
