import { AssetField } from 'components/asset-management/types/asset-management-field.type';

// We only want to allow editing public / non-public if an asset field with key 'public' exists and is editable.
const canEditPrivacy = (assetFields: AssetField[]): boolean => {
    const privacyField = assetFields.find((field) => field.key === 'public');
    if (!privacyField) return false;
    if (!privacyField.editable) return false;
    return true;
};

export { canEditPrivacy };
