import { useMemo } from 'react';
import { AssetV2 } from 'types/asset.type';
import { useAssetManagementConfigContext } from '../context/asset-management-config.context';
import { getAssetsNotValidated } from '../components/edit-asset/utilities';
import { AssetsNotValidated } from '../types/asset-management-edit.type';

const useAMAssetsNotValidated = (assets: AssetV2<unknown, unknown>[] = []): AssetsNotValidated => {
    const { requiredAssetModels } = useAssetManagementConfigContext();

    // Create a new array reference if any property of any asset changes
    const assetsCopy = assets.map((asset) => ({ ...asset }));

    return useMemo(() => (assetsCopy ? getAssetsNotValidated(assetsCopy, requiredAssetModels) : {}), [assetsCopy]);
};

export default useAMAssetsNotValidated;
