import useComponentStore, { ComponentStoreFields } from 'components/data/ComponentStore/hooks/useComponentStore';
import { useAssetManagementConfigContext } from '../context/asset-management-config.context';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useAMData = <Data>(fields?: ComponentStoreFields) => {
    const { storeName } = useAssetManagementConfigContext();

    const data = useComponentStore<Data>(storeName, {
        fields: fields
    });

    return {
        data
    };
};

export default useAMData;
