import { useSelector } from 'react-redux';
import Store from 'types/store.type';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import { useAssetManagementConfigContext } from '../context/asset-management-config.context';

const useAMAssetSelection = (
    assetId?: string
): {
    isSelected: boolean;
    selectAsset: (assetId: string) => void;
    setSelectedAssets: (assetIds: string[]) => void;
} => {
    const { storeName } = useAssetManagementConfigContext();

    const isSelected = useSelector((state: Store) => {
        const componentStore = state.componentStore;
        if (!componentStore || !assetId || !storeName) {
            return false;
        }

        const assetManagementStore = componentStore[storeName];

        if (!assetManagementStore) {
            return false;
        }

        return assetManagementStore.state.selectedAssetIds.includes(assetId);
    });

    const selectAsset = (assetId: string) => {
        const selectedAssetIds = ComponentStoreHelpers.get(storeName)?.state.selectedAssetIds || [];

        if (selectedAssetIds.includes(assetId)) {
            ComponentStoreHelpers.setModel(
                storeName,
                'state.selectedAssetIds',
                selectedAssetIds.filter((id) => id !== assetId)
            );
            return;
        }

        ComponentStoreHelpers.setModel(storeName, 'state.selectedAssetIds', [...selectedAssetIds, assetId]);
    };

    const setSelectedAssets = (assetIds: string[]) => {
        ComponentStoreHelpers.setModel(storeName, 'state.selectedAssetIds', assetIds);
    };

    return {
        isSelected,
        selectAsset,
        setSelectedAssets
    };
};

export default useAMAssetSelection;
