import { AssetField, AssetFieldsGrouping } from '../types/asset-management-field.type';

// We want to filter out the inputs that are not editable from the assetFieldsGrouping.
const getAssetFieldGroupingVerified = (assetFieldsGrouping: AssetFieldsGrouping, assetFields: AssetField[]): AssetFieldsGrouping => {
    const assetFieldGroupingVerified: AssetFieldsGrouping = [];

    assetFieldsGrouping.forEach((group) => {
        const inputsVerified = group.inputs.filter((input) => {
            return assetFields.find((field) => field.key === input && field.editable);
        });
        assetFieldGroupingVerified.push({ ...group, inputs: inputsVerified });
    });

    return assetFieldGroupingVerified;
};

export { getAssetFieldGroupingVerified };
