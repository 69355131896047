import { CollectionTreeChild } from 'types/collection.type';

// Find the path, an array of collections with a parent child relation, to the collection with the given id.
const findPath = (collectionTree: CollectionTreeChild[], id: string): CollectionTreeChild[] | null => {
    for (let i = 0; i < collectionTree.length; i++) {
        if (collectionTree[i].id === id) {
            return [collectionTree[i]];
        }

        const path = findPath(collectionTree[i].children, id);
        if (path) {
            return [collectionTree[i], ...path];
        }
    }

    return null;
};

// Returns the path, an array of collections with a parent child relation, to the collection with the given id.
const getNestedCollectionPath = (collectionTree: CollectionTreeChild[], collectionId: string): CollectionTreeChild[] => {
    const path = findPath(collectionTree, collectionId);
    return path || [];
};

export { getNestedCollectionPath };
