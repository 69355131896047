import EditorData from 'components/editor-data/EditorData';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import { getTemplateData } from 'components/template-designer/helpers/data.helpers';
import { MetricsUsageUseCase } from '../types/asset-management.service.type';

const getSelectorUseCaseId = (useCase: MetricsUsageUseCase): string | number | null => {
    switch (useCase) {
        case 'campaign':
        case 'campaignconcept':
            return EditorData.getId();
        case 'template':
            return getTemplateData('id');
        case 'creative':
            return ComponentStoreHelpers.getItem('CreativeEditor', 'referenceId') || null;
        default:
            return null;
    }
};

export { getSelectorUseCaseId };
