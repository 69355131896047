import React, { useState } from 'react';
import { ToggleButtonGroupClasses } from '@mui/material/ToggleButtonGroup';
import AssetEditorSliderRow from 'components/assets/AssetEditor/components/AssetEditorSliderRow';
import Translation from 'components/data/Translation';
import ComponentStore from 'components/data/ComponentStore';
import AssetEditorState from 'components/assets/AssetEditor/interfaces/AssetEditorState';
import ToggleButtonGroup from 'components/ui-components-v2/ToggleGroup';
import ToggleButton from 'components/ui-components-v2/ToggleButton';
import compressorToggleButtons from '../data/compressor-toggle-buttons';
import ImageCompressorState, { CompressorMode } from '../interfaces/image-compressor-state';
import ImageCompressorHelper from '../helpers/image-compressor-helper';

export const DEFAULT_QUALITY_VALUE = 80;
export const DEFAULT_COMPRESSION_MODE: CompressorMode = 'best'; // The default mode to use when the image compressor is first opened.
export const DEFAULT_COMPRESSION_LEVEL = 20; // The default compression level to use when the mode is 'custom'.
const MIN_QUALITY_VALUE = 5; // The minimum quality value that can be set on the slider.
const MAX_QUALITY_VALUE = 99; // The maximum quality value that can be set on the slider.

interface Classes {
    root?: string;
    toggleButtonGroupContainer?: string;
    toggleButtonGroup?: Partial<ToggleButtonGroupClasses>;
}

interface Props {
    classes?: Classes;
}

/**
 * A React functional component that renders the image compressor mode control.
 * It allows the user to select between compressor modes and adjust the compression level using a slider.
 */
const ImageCompressorMode: React.FC<Props> = ({ classes }) => {
    const imageCompressorState: ImageCompressorState | undefined = ComponentStore.get('ImageCompressor');
    const [mode, setMode] = useState<CompressorMode>(imageCompressorState?.mode ?? DEFAULT_COMPRESSION_MODE);
    const sliderDefaultValue = imageCompressorState?.compressionLevel ? 100 - imageCompressorState.compressionLevel : DEFAULT_QUALITY_VALUE;
    const [sliderValue, setSliderValue] = useState(sliderDefaultValue);

    /**
     * Handles the image compressor compression level update.
     * @param compressionLevel - The new compression level to set.
     */
    const updateCompressionLevelAndSliderValue = (compressionLevel?: number) => {
        // Updating the 'compressionLevel' in the 'ImageCompressor' model.
        ComponentStore.setModel<ImageCompressorState, 'compressionLevel'>('ImageCompressor', 'compressionLevel', compressionLevel);

        if (compressionLevel) {
            const quality = ImageCompressorHelper.getQualityFromCompressionLevel(compressionLevel);
            setSliderValue(quality); // Updating the slider value state to reflect the new quality
        }
    };

    /**
     * Gets the compression level based on the mode.
     */
    const getCompressionLevel = (mode: CompressorMode): number | undefined => {
        if (mode === 'custom') {
            const assetEditor: AssetEditorState | undefined = ComponentStore.get('AssetEditor');

            if (!assetEditor) {
                return;
            }

            return assetEditor.imageCompressorState?.compressionLevel ?? DEFAULT_COMPRESSION_LEVEL;
        }
    };

    /**
     * Sets the image compressor quality based on the current mode or slider value.
     */
    const updateCompressionModeAndLevel = (mode: CompressorMode) => {
        const compressionLevel = getCompressionLevel(mode);

        // Update the compression level.
        updateCompressionLevelAndSliderValue(compressionLevel);

        // Update the mode state.
        ComponentStore.setModel<ImageCompressorState, 'mode'>('ImageCompressor', 'mode', mode);
    };

    /**
     * Handles the change of the mode in the image compressor slider row.
     * @param mode - The new mode to set.
     */
    const onModeChange = (mode: CompressorMode | null) => {
        if (mode === null) {
            return; // Do nothing if the mode is null.
        }

        setMode(mode); // Update the mode state.
        updateCompressionModeAndLevel(mode); // Set the compression level based on the new mode.
    };

    /**
     * Handles the change event of the slider input element.
     * @param {React.ChangeEvent<HTMLInputElement>} event - The change event object.
     */
    const onSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        const compressionLevel = ImageCompressorHelper.getCompressionLevelFromQuality(value);

        updateCompressionLevelAndSliderValue(compressionLevel); // Update the compression level.
    };

    return (
        <div className={classes?.root}>
            <ToggleButtonGroup
                value={mode}
                color="primary"
                size="small"
                fullWidth
                exclusive
                onChange={(event, value) => {
                    onModeChange(value);
                }}>
                {compressorToggleButtons.map((item, index) => {
                    return (
                        <ToggleButton key={index} value={item.value}>
                            {item.text}
                        </ToggleButton>
                    );
                })}
            </ToggleButtonGroup>
            {mode === 'custom' && (
                <div className="image-compressor-stepper__content__slider">
                    <AssetEditorSliderRow
                        minSliderValue={MIN_QUALITY_VALUE}
                        maxSliderValue={MAX_QUALITY_VALUE}
                        sliderValue={sliderValue}
                        onSliderChange={onSliderChange}
                        title={Translation.get('assetGalleryDialog.imageCompressor.quality', 'content-space')}
                    />
                </div>
            )}
        </div>
    );
};

export default ImageCompressorMode;
