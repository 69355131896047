import { AssetStatus } from 'types/asset.type';
import { Right } from '../types/asset-management-right.type';

// Check if the user can organise the asset based on the asset status and the users rights.
const canOrganise = (userHasRight: (right: Right) => boolean, status?: AssetStatus): boolean => {
    if (userHasRight('write') && status === 'draft') return true;
    if (userHasRight('management') && status && ['available', 'draft'].includes(status)) return true;
    return false;
};

export { canOrganise };
