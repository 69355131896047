import * as Sentry from '@sentry/react';
import { AssetV2, AssetToCreate, AssetType } from 'types/asset.type';
import { Collection, CollectionToCreate } from 'types/collection.type';
import {
    AssetListResponse,
    CollectionListResponse,
    CollectionsTreeResponse,
    AssetListPayload,
    MetricsUsagePayoad,
    MetricsUsageResponse,
    AssetCreateResponse,
    VersionsResponse
} from '../types/asset-management.service.type';
import AssetManagementRequest from '../data';

export default class AssetManagementBaseService {
    /**
     * Create an asset.
     */
    static async createAsset(asset: AssetToCreate<unknown, unknown>): Promise<AssetCreateResponse> {
        try {
            const response = await AssetManagementRequest.post('asset', asset);
            return response.data;
        } catch (error) {
            Sentry.captureException(error);
            throw error;
        }
    }

    /**
     * Get an asset from the API.
     */
    static async getAsset(assetId: string, params?: { released?: boolean; versionNumber?: number }): Promise<AssetV2<unknown, unknown>> {
        try {
            const response = await AssetManagementRequest.get(`asset/${assetId}`, { params });
            return response.data;
        } catch (error) {
            Sentry.captureException(error);
            throw error;
        }
    }

    /**
     * Patch an existing asset.
     */
    static async patchAsset(asset: AssetV2<unknown, unknown>): Promise<AssetV2<unknown, unknown>> {
        try {
            const response = await AssetManagementRequest.patch(`asset/${asset._id}`, asset);
            return response.data;
        } catch (error) {
            Sentry.captureException(error);
            throw error;
        }
    }

    /**
     * Delete an asset.
     */
    static async deleteAsset(assetId: string, permanent?: boolean): Promise<void> {
        try {
            await AssetManagementRequest.delete(`asset/${assetId}?permanent=${Boolean(permanent)}`);
        } catch (error) {
            Sentry.captureException(error);
            throw error;
        }
    }

    /**
     * Release an asset.
     */
    static async releaseAsset(assetId: string): Promise<AssetV2<unknown, unknown>> {
        try {
            const response = await AssetManagementRequest.patch(`asset/${assetId}/release`);
            return response.data;
        } catch (error) {
            Sentry.captureException(error);
            throw error;
        }
    }

    /**
     * Create assets.
     */
    static async createAssetBatch(assets: AssetToCreate<unknown, unknown>[]): Promise<AssetCreateResponse[]> {
        try {
            const response = await AssetManagementRequest.post('asset/batch', assets);
            return response.data;
        } catch (error) {
            Sentry.captureException(error);
            throw error;
        }
    }

    /**
     * Create assets.
     */
    static async deleteAssetBatch(assetIds: string[], permanent?: boolean): Promise<void> {
        try {
            await AssetManagementRequest.delete(`asset/batch?permanent=${Boolean(permanent)}`, { data: { assetIds } });
        } catch (error) {
            Sentry.captureException(error);
            throw error;
        }
    }

    /**
     * Update assets.
     */
    static async patchAssetBatch(assets: AssetV2<unknown, unknown>[]): Promise<AssetV2<unknown, unknown>[]> {
        try {
            const response = await AssetManagementRequest.patch('asset/batch', assets);
            return response.data;
        } catch (error) {
            Sentry.captureException(error);
            throw error;
        }
    }

    /**
     * Get the list of assets.
     * The requestData must carry a 'type', and might carry filter, pagination and sorting information.
     */
    static async getAssetBatch(params: AssetListPayload, filterExpired?: boolean): Promise<AssetListResponse> {
        try {
            const response = await AssetManagementRequest.post('asset/filter', { ...params }, { params: { filterExpired } });
            return response.data;
        } catch (error) {
            Sentry.captureException(error);
            throw error;
        }
    }

    /**
     * Get the list of library assets for Cape library templates.
     * The requestData must carry a 'type', and might carry filter, pagination and sorting information.
     */
    static async getAssetLibraryBatch(params: AssetListPayload, filterExpired?: boolean): Promise<AssetListResponse> {
        try {
            const response = await AssetManagementRequest.post('asset/capelibrary', { ...params }, { params: { filterExpired } });
            return response.data;
        } catch (error) {
            Sentry.captureException(error);
            throw error;
        }
    }

    /**
     * Empty the bin.
     */
    static async emptyBin(type: AssetType): Promise<void> {
        try {
            await AssetManagementRequest.delete(`asset/binned?type=${type}`);
        } catch (error) {
            Sentry.captureException(error);
            throw error;
        }
    }

    /**
     * Create a collection.
     */
    static async createCollection(collection: CollectionToCreate): Promise<Collection> {
        try {
            const response = await AssetManagementRequest.post('collection', collection);
            return response.data;
        } catch (error) {
            Sentry.captureException(error);
            throw error;
        }
    }

    /**
     * Get a collection
     */
    static async getCollection(collectionId: string): Promise<Collection> {
        try {
            const response = await AssetManagementRequest.get(`collection/${collectionId}`);
            return response.data;
        } catch (error) {
            Sentry.captureException(error);
            throw error;
        }
    }

    /**
     * Patch a collection.
     */
    static async patchCollection(collection: Collection): Promise<Collection> {
        try {
            const response = await AssetManagementRequest.patch<Collection>(`collection/${collection.id}`, collection);
            return response.data;
        } catch (error) {
            Sentry.captureException(error);
            throw error;
        }
    }

    /**
     * Delete a collection
     */
    static async deleteCollection(collectionId: string): Promise<void> {
        try {
            return await AssetManagementRequest.delete(`collection/${collectionId}`);
        } catch (error) {
            Sentry.captureException(error);
            throw error;
        }
    }

    /**
     * Delete collections in batch
     */
    static async deleteCollectionBatch(collectionIds: string[]): Promise<void> {
        try {
            await AssetManagementRequest.delete('collection/batch', { data: { collectionIds } });
        } catch (error) {
            Sentry.captureException(error);
            throw error;
        }
    }

    /**
     * Get the list of collections
     */
    static async getCollectionBatch(params: object, filterExpired?: boolean): Promise<CollectionListResponse> {
        try {
            const response = await AssetManagementRequest.post('collection/filter', { ...params }, { params: { filterExpired } });
            return response.data;
        } catch (error) {
            Sentry.captureException(error);
            throw error;
        }
    }

    /**
     * Get the list of collections
     */
    static async getCollectionsTree(params: object): Promise<CollectionsTreeResponse> {
        try {
            const response = await AssetManagementRequest.get('collection/treeView', { params });
            return response.data;
        } catch (error) {
            Sentry.captureException(error);
            throw error;
        }
    }

    /**
     * Get collection items
     */
    static async getCollectionItems(collectionId: string, params?: object, filterExpired?: boolean): Promise<AssetListResponse> {
        try {
            const response = await AssetManagementRequest.post(`collection/${collectionId}/items`, { ...params }, { params: { filterExpired } });
            return response.data;
        } catch (error) {
            Sentry.captureException(error);
            throw error;
        }
    }

    /**
     * Let the metrics usage endpoint know that an asset has been used.
     */
    static async addUsage(params: MetricsUsagePayoad): Promise<void> {
        try {
            const response = await AssetManagementRequest.post('metrics/usage', { ...params });
            return response.data;
        } catch (error) {
            Sentry.captureException(error);
            throw error;
        }
    }

    /*
     * Get the usage of an asset.
     */
    static async getUsage(assetId: string): Promise<MetricsUsageResponse> {
        try {
            const response = await AssetManagementRequest.get(`metrics/${assetId}/usage`);
            return response.data;
        } catch (error) {
            Sentry.captureException(error);
            throw error;
        }
    }

    /**
     * Get the options for a label.
     */
    static async getLabelOptions(type: AssetType, label: string): Promise<string[]> {
        try {
            const response = await AssetManagementRequest.get(`label/${type}/${label}`);
            return response.data;
        } catch (error) {
            Sentry.captureException(error);
            throw error;
        }
    }

    /**
     * Get the versions of an asset.
     */
    static async getVerions(assetId: string): Promise<VersionsResponse> {
        try {
            const response = await AssetManagementRequest.get(`asset/${assetId}/versions`);
            return response.data;
        } catch (error) {
            Sentry.captureException(error);
            throw error;
        }
    }

    static async assetDownload(itemIds: string[]): Promise<{ url: string }> {
        try {
            const response = await AssetManagementRequest.post('asset/download', { itemIds });
            return response.data;
        } catch (error) {
            Sentry.captureException(error);
            throw error;
        }
    }
}
