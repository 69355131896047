import { AssetStatus, AssetType, AssetV2 } from 'types/asset.type';
import { set } from 'lodash';
import { Filters } from 'types/filter.type';
import { Collection } from 'types/collection.type';
import { FetchPayload, SortDirection, SortField } from '../types/asset-management.service.type';

interface ListState {
    stateList: AssetV2<unknown, unknown>[] | Collection[] | null;
    stateNextPageToken: number;
    stateFilters: Filters;
    stateSortField?: SortField;
    stateSortDirection?: SortDirection;
}

interface Params {
    type: AssetType;
    nextPageToken?: number;
    filters?: Filters;
    sortField?: SortField;
    sortDirection?: SortDirection;
}

const getListParams = (type: AssetType, listState: ListState, status: AssetStatus, shouldRefresh: boolean, payload?: FetchPayload): Params => {
    const { stateNextPageToken, stateFilters, stateSortField, stateSortDirection } = listState;
    const { filters, sorting } = payload ?? {};

    const params: Params = { type };

    // If no new filtering is provided and there is a next page, we use the current filters and the next page token.
    if (!shouldRefresh && stateNextPageToken) {
        set(params, 'nextPageToken', stateNextPageToken);
    }

    // If new filters are provided, we use them.
    if (filters) {
        set(params, 'filters', filters);
    } else if (stateFilters && Object.keys(stateFilters).length > 0) {
        set(params, 'filters', stateFilters);
    }

    if (status !== 'available') {
        set(params, 'filters.status', status);
    }

    const { sortField, sortDirection } = sorting ?? {};

    // If new sort field is provided, we use it.
    if (sorting?.sortField) {
        set(params, 'sortField', sortField);
    } else if (stateSortField) {
        set(params, 'sortField', stateSortField);
    }

    // If new sort direction is provided, we use it.
    if (sorting?.sortDirection) {
        set(params, 'sortDirection', sortDirection);
    } else if (stateSortDirection) {
        set(params, 'sortDirection', stateSortDirection);
    }

    return params;
};

export { getListParams };
