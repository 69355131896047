import { FilterSetup, FilterOption } from 'types/filter.type';
import Setup from 'components/data/Setup';
import Translation from 'components/data/Translation';
import { UNASSIGNED_KEY } from 'components/ui-base/GenericFilter/constants';
import { AssetField } from '../types/asset-management-field.type';
import { MULTIS } from '../constants';

const getFilterOptions = (field: AssetField) => {
    const options: FilterOption[] = [];
    field.items?.forEach((item) => {
        if (item.value !== '_all') {
            options.push({ key: item.value, value: item.label });
        }
    });
    if (options.length) options.push({ key: UNASSIGNED_KEY, value: Translation.get('actions.unassigned', 'common') });
    return options;
};

const getCollectionFilterSetup = (assetFields: AssetField[]): FilterSetup => {
    const collectionFilterSetup: FilterSetup = [];
    Object.entries(MULTIS).forEach(([key, value]) => {
        if (Setup.hasModule(value)) {
            const configuredField = assetFields.find((assetField) => assetField.key === key);
            if (configuredField) {
                collectionFilterSetup.push({
                    label: configuredField.name,
                    name: configuredField.key,
                    type: 'selectMultiple',
                    options: configuredField.items ? getFilterOptions(configuredField) : []
                });
            }
        }
    });

    // Check if we should add a publicly available filter
    const publicField = assetFields.find((field) => field.key === 'public');
    if (publicField?.filterable) {
        collectionFilterSetup.push({
            label: publicField.name,
            name: publicField.key,
            type: 'selectMultiple',
            options: [
                { key: 'public', value: Translation.get('labels.public', 'asset-management') },
                { key: 'private', value: Translation.get('labels.private', 'asset-management') }
            ]
        });
    }

    return collectionFilterSetup.filter((filter) => filter.options.length > 0);
};

export { getCollectionFilterSetup };
