import { Filters } from 'types/filter.type';

const getChangedFilter = (prevFilters: Filters, key: string, value: string | string[]): Filters => {
    const filters = (() => {
        if (value.length) {
            return { ...prevFilters, [key]: value };
        }
        delete prevFilters[key];
        return prevFilters;
    })();

    return filters;
};

export { getChangedFilter };
