import { DEFAULT_COMPRESSION_LEVEL, DEFAULT_QUALITY_VALUE } from '../components/image-compressor-slider-row';

/**
 * A helper class for image compression related operations.
 */
class ImageCompressorHelper {
    /**
     * Calculates the quality value for image compression based on the given compression level.
     * @param compressionLevel - The compression level, a number between 0 and 100.
     * @returns The quality value for image compression, a number between 0 and 100.
     */
    static getQualityFromCompressionLevel = (compressionLevel?: number) => {
        return compressionLevel ? 100 - compressionLevel : DEFAULT_QUALITY_VALUE;
    };

    /**
     * Returns the compression level based on the given image quality.
     * If imageQuality is not provided, returns the default compression level.
     * @param imageQuality The quality of the image, expressed as a number between 0 and 100.
     * @returns The compression level, expressed as a number between 0 and 100.
     */
    static getCompressionLevelFromQuality = (imageQuality?: number) => {
        return imageQuality || imageQuality === 0 ? 100 - imageQuality : DEFAULT_COMPRESSION_LEVEL;
    };

    /**
     * Calculates the compression percentage based on the quality value.
     * @returns The compression percentage.
     */
    static calculateCompressionPercentage = (oldImageSize: number, newImageSize: number) => {
        if (newImageSize <= oldImageSize) {
            return Math.floor(100 - (newImageSize * 100) / oldImageSize);
        }

        return Math.floor(100 - (oldImageSize * 100) / newImageSize);
    };
}

export default ImageCompressorHelper;
