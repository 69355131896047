import React from 'react';
import Button from '@mui/material/Button';
import AssetEditorControllerStepper from 'components/assets/AssetEditor/components/AssetEditorControllerStepper';
import useAssetEditorStepperHook from 'components/assets/AssetEditor/hooks/asset-editor-stepper-hook';
import AssetEditorActionRow from 'components/assets/AssetEditor/components/AssetEditorActionsRow/asset-editor-actions';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import StepperLoading from 'components/assets/AssetEditor/components/AssetEditorControllerStepper/components/stepper-loading';
import AssetEditorState from 'components/assets/AssetEditor/interfaces/AssetEditorState';
import Translation from 'components/data/Translation';
import AssetEditorReviewStep from 'components/assets/AssetEditor/components/AssetEditorReviewStep';
import OutpaintController from 'components/assets/AssetGalleryCropper/components/image-cropper-wrapper/components/image-outpaint-controller';
import { OutpaintState } from 'components/assets/AssetGalleryCropper/interfaces/asset-cropper-state';
import magicEditorSteps from './data/magic-editor-steps';
import useAssetEditorControllersHook from '../../hooks/use-asset-editor-controllers-hook';
import './styles/main.scss';

/**
 * The `AssetOutpaintController` component is responsible handling user interactions
 * for the outpaint feature. This component uses the `useAssetEditorStepperHook` and `useComponentStore`
 * hooks to manage the state of the outpaint editor feature.
 */
const AssetOutpaintController: React.FC = () => {
    const { stepperStep } = useAssetEditorStepperHook(); // Hook to manage the asset editor stepper.
    const { onApply, onCancel, abortController, runAssetEditorImageProcess, onClear } = useAssetEditorControllersHook();

    const { loading } = useComponentStore<AssetEditorState>('AssetEditor', {
        fields: { loading: 'loading' }
    }); // Hook to manage the asset editor state.

    const { outpaintData } = useComponentStore<OutpaintState>('Outpaint', {
        fields: {
            outpaintData: 'outpaintData'
        }
    });

    /** Remove objects of an image based on the mask that the user has created. */
    const outpaint = () => {
        runAssetEditorImageProcess('outpaint', magicEditorSteps.length, abortController, { outpaintData });
    };

    return (
        <>
            <AssetEditorControllerStepper
                steps={magicEditorSteps}
                isLoading={loading}
                loadingChildren={<StepperLoading onCancelClick={onCancel} text={'Generating...'} />}>
                {stepperStep === 0 && (
                    <div>
                        <OutpaintController />
                        <AssetEditorActionRow>
                            <Button onClick={onClear} variant="text">
                                {Translation.get('actions.reset', 'common')}
                            </Button>
                            <Button onClick={outpaint} variant="contained" color="primary">
                                {Translation.get('assetGalleryDialog.assetEditor.generate', 'content-space')}
                            </Button>
                        </AssetEditorActionRow>
                    </div>
                )}

                {stepperStep === 1 && <AssetEditorReviewStep onCancel={onCancel} onApply={onApply} />}
            </AssetEditorControllerStepper>
        </>
    );
};

export default AssetOutpaintController;
