import { useEffect, useRef } from 'react';
import ImageFileService from 'services/image-file/image.service';
import ImageModifierService from 'services/image-modifier/image-modifier.service';
import assetEditorSidebarRows from 'components/assets/AssetEditor/data/side-bar-rows';
import AssetEditorHelper from 'components/assets/AssetEditor/helpers/asset-editor-helper';
import useAssetEditorStepperHook from 'components/assets/AssetEditor/hooks/asset-editor-stepper-hook';
import AssetEditorState from 'components/assets/AssetEditor/interfaces/AssetEditorState';
import AssetMaskEditorState from 'components/assets/AssetMaskEditor/interface/mask-editor-state';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import Translation from 'components/data/Translation';
import {
    AssetEditorControllerProps,
    AssetEditorProcessType,
    InpaintProps,
    ObjectRemoverProps,
    OutpaintProps,
    PaintByExampleProps,
    ProcessProps,
    ProcessTypeOptions,
    ProcessTypeProps
} from '../interfaces/AssetEditorControllerProps';

/**
 * A custom hook that manages the controllers for the asset editor.
 */
const useAssetEditorControllersHook = (): AssetEditorControllerProps => {
    const { handleBack, handleNext, stepperStep } = useAssetEditorStepperHook(); // Hook to manage the asset editor stepper.
    const abortController = useRef(new AbortController());

    const { maskAssetSrc } = useComponentStore<AssetMaskEditorState>('AssetMaskEditor', {
        fields: { isMaskEdited: 'isMaskEdited', maskAssetSrc: 'maskAssetSrc', isEditTriggered: 'isEditTriggered' }
    }); // Hook to manage the Object Remover state.

    const { previewAssetSrc, componentKey, originalAssetSrc, modifiedAssetSrc } = useComponentStore<AssetEditorState>('AssetEditor', {
        fields: {
            previewAssetSrc: 'previewAssetSrc',
            componentKey: 'componentKey',
            originalAssetSrc: 'originalAssetSrc',
            modifiedAssetSrc: 'modifiedAssetSrc'
        }
    });

    const runAssetEditorImageProcess = async <T extends AssetEditorProcessType>(
        processType: T,
        totalSteps: number, // The total step Amount for the active controller
        abortController: React.RefObject<AbortController>,
        options: T extends 'inpaint' | 'paintByExample' ? ProcessTypeOptions<T> : Record<string, never>
    ) => {
        if (usesMaskEditor) ComponentStoreHelpers.setModel('AssetMaskEditor', 'isMaskEditDisabled', true);
        ComponentStoreHelpers.setModel('AssetEditor', 'loading', true); //Set loading to true

        if (stepperStep < totalSteps - 1) {
            handleNext();
        }

        const imageSrc = AssetEditorHelper.getAssetUrl(originalAssetSrc, modifiedAssetSrc);

        try {
            const [imageFileUrl, maskFileUrl] = await Promise.all([
                ImageFileService.uploadBase64(imageSrc),
                maskAssetSrc ? ImageFileService.uploadBase64(maskAssetSrc) : undefined
            ]);

            if (!imageFileUrl || (maskAssetSrc && !maskFileUrl)) {
                return;
            }

            const finalOptions: ProcessTypeProps<T> = {
                imageFileUrl,
                abortController,
                ...(processType === 'outpaint' ? { outpaintData: (options as any).outpaintData } : {}),
                ...(processType === 'objectRemover' || processType === 'inpaint' || processType === 'paintByExample' ? { maskFileUrl } : {}),
                ...(processType === 'inpaint' ? { prompt: (options as InpaintProps).prompt } : {}),
                ...(processType === 'paintByExample' ? { exampleFileUrl: (options as PaintByExampleProps).exampleFileUrl } : {})
            } as ProcessTypeProps<T>;

            if (!abortController?.current?.signal.aborted) {
                try {
                    let newImageUrl: string | undefined;
                    switch (processType) {
                        case 'objectRemover':
                            newImageUrl = await ImageModifierService.removeObjectFromImage(finalOptions as ObjectRemoverProps);
                            break;
                        case 'inpaint':
                            newImageUrl = await ImageModifierService.inpaint(finalOptions as InpaintProps);
                            break;
                        case 'paintByExample':
                            newImageUrl = await ImageModifierService.paintByExample(finalOptions as PaintByExampleProps);
                            break;
                        case 'backgroundRemover':
                            newImageUrl = await ImageModifierService.removeBackground(finalOptions as ProcessProps);
                            break;
                        case 'outpaint':
                            newImageUrl = await ImageModifierService.outpaint(finalOptions as OutpaintProps);
                            break;
                    }

                    if (newImageUrl) {
                        ImageFileService.convertImageUrlToBase64(newImageUrl, (base64) => {
                            ComponentStoreHelpers.setMultiModels('AssetEditor', [
                                ['loading', false],
                                ['previewAssetSrc', base64]
                            ]);
                            if (usesMaskEditor) ComponentStoreHelpers.setModel('AssetMaskEditor', 'isEditTriggered', false);
                        });
                    }
                } catch (error) {
                    ImageModifierService.handleImageModificationError(error);
                }
            }
        } catch (err) {
            SnackbarUtils.error(Translation.get('feedback.errors.oops', 'common'));
            ComponentStoreHelpers.setModel('AssetEditor', 'loading', false);
            throw err;
        }
    };

    // Check if the current component uses the mask editor.
    const usesMaskEditor = assetEditorSidebarRows.find((row) => row.componentKey === componentKey)?.usesMaskEditor === true;

    useEffect(() => {
        return () => {
            handleCancel(true);
        };
    }, []);

    /** Apply's the changes made by the Object Remover feature to the modified asset state and returns to the preview asset component. */
    const handleApply = () => {
        ComponentStoreHelpers.setMultiModels('AssetEditor', [
            ['modifiedAssetSrc', previewAssetSrc],
            ['componentKey', 'previewAsset']
        ]);
    };

    /** When the user clicks on the Object Remover button, the `isEditTriggered` state will be set to true and the next step will be shown. */
    const handleEdit = () => {
        // Renew the abort controller on for every new edit
        abortController.current = new AbortController();
        if (usesMaskEditor)
            ComponentStoreHelpers.setMultiModels('AssetMaskEditor', [
                ['isEditTriggered', true],
                ['isMaskAssetSrcReady', false]
            ]);
    };

    /** Triggers the reset of the Object Remover feature, by changing the `isResetTriggered` state to true. */
    const handleClear = () => {
        if (usesMaskEditor) ComponentStoreHelpers.setModel('AssetMaskEditor', 'isResetTriggered', true);
        if (componentKey === 'outpaint') ComponentStoreHelpers.setModel('Outpaint', 'isResetTriggered', true);
    };

    /**
     * Resets the state of the AssetEditor and AssetObjectRemover components.
     */
    const resetState = () => {
        const assetEditorState = ComponentStoreHelpers.get('AssetEditor');

        if (!assetEditorState) {
            return;
        }

        ComponentStoreHelpers.setMultiModels('AssetEditor', [
            ['previewAssetSrc', ''],
            ['loading', false]
        ]);

        const assetMaskEditorState = ComponentStoreHelpers.get('AssetMaskEditor');

        if (!assetMaskEditorState) {
            return;
        }

        if (usesMaskEditor) {
            ComponentStoreHelpers.setMultiModels('AssetMaskEditor', [
                ['isEditTriggered', false],
                ['isMaskEditDisabled', false]
            ]);
        }
    };

    /** Goes back the previous step and resets preview asset state. */
    const handleCancel = (reset = false) => {
        // cancel the request if the editor is loading
        abortController.current.abort();

        resetState();

        if (!reset) {
            if (stepperStep > 0) handleBack();
            if (stepperStep === 0) ComponentStoreHelpers.setModel('AssetEditor', 'componentKey', 'previewAsset'); // Go back to the preview asset component.
        }

        // Create a new AbortController
        abortController.current = new AbortController();
    };

    return {
        abortController: abortController,
        runAssetEditorImageProcess: runAssetEditorImageProcess,
        onApply: handleApply,
        onCancel: handleCancel,
        onReset: resetState,
        onClear: handleClear,
        onEdit: handleEdit
    };
};

export default useAssetEditorControllersHook;
