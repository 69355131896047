import cloneDeep from 'helpers/cloneDeep';
import { AssetListPayload } from '../types/asset-management.service.type';

const transformAssetParamsForRootKeys = (params): AssetListPayload => {
    const inputParams = cloneDeep(params);
    if (inputParams && inputParams.filters) {
        const { filters } = inputParams;

        if (filters.collections) {
            inputParams.collections = filters.collections;
            delete filters.collections;
        }
    }
    return inputParams;
};

export { transformAssetParamsForRootKeys };
