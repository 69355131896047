import { Collection } from 'types/collection.type';
import { AssetManagementStoreType } from '../types/asset-management-store.type';

const findCollectionInStore = (
    collectionId: string,
    collectionsList: Collection[] | null,
    collectionsContent: AssetManagementStoreType['collectionsContent']
): Collection | null => {
    const foundRootCollection = collectionsList && collectionsList.find((collection) => collection.id === collectionId);
    if (foundRootCollection) return foundRootCollection;

    for (const collectionKey in collectionsContent) {
        const foundCollection =
            collectionsContent[collectionKey].subCollectionsList &&
            collectionsContent[collectionKey].subCollectionsList?.find((collection) => collection.id === collectionId);
        if (foundCollection) return foundCollection;
    }

    return null;
};

export { findCollectionInStore };
