import { StoreTypes } from 'types/store.type';
import { AssetV2 } from 'types/asset.type';
import { Filters } from 'types/filter.type';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import { AssetListResponse, SortDirection, SortField } from '../types/asset-management.service.type';
import { getAssetListStateFromResponse } from './getAssetListStateFromResponse';

const storeAssetListResponse = (
    response: AssetListResponse,
    storeName: StoreTypes,
    assetsList: AssetV2<unknown, unknown>[] | null,
    params: {
        filters?: Filters;
        sortField?: SortField;
        sortDirection?: SortDirection;
    },
    shouldRefresh: boolean,
    rootModel: string
): void => {
    const listState = getAssetListStateFromResponse(response, assetsList || [], shouldRefresh, params);

    const modelsToStore: [string, unknown][] = [];

    Object.entries(listState).forEach(([key, value]) => {
        modelsToStore.push([`${rootModel}${key}`, value]);
    });

    if (response.collections) modelsToStore.push([`${rootModel}subCollectionsList`, response.collections]);

    ComponentStoreHelpers.setMultiModels(storeName, modelsToStore);
};

export { storeAssetListResponse };
