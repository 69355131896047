import AssetEditorHelper from 'components/assets/AssetEditor/helpers/asset-editor-helper';

/**
 * Helper class for exporting images.
 */
class ImageExportHelper {
    /**
     * Create a canvas for the cropped image.
     * @param {number} width - The width of the canvas.
     * @param {number} height - The height of the canvas.
     * @returns {HTMLCanvasElement} - The created canvas.
     */
    static createCanvas = (width: number, height: number) => {
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        return canvas;
    };

    /**
     * Resize an image to the specified width and height.
     * @param {HTMLImageElement} imageElement - The image element to be resized.
     * @param {number} newWidth - The new width for the image.
     * @param {number} newHeight - The new height for the image.
     * @returns {Promise<HTMLImageElement>} - The resized image element.
     */
    static resizeImage = async (imageElement: HTMLImageElement, newWidth: number, newHeight: number, imageQuality: number): Promise<HTMLImageElement> => {
        const canvas = this.createCanvas(newWidth, newHeight);

        const ctx = canvas.getContext('2d');

        if (!ctx) {
            return imageElement;
        }

        // Draw the image on the canvas scaled to the new size.
        ctx.drawImage(imageElement, 0, 0, newWidth, newHeight);

        const blob = await AssetEditorHelper.getBlobFromCanvas(canvas, imageElement.src, imageQuality); // Get the blob from the canvas.

        if (!blob) {
            return imageElement;
        }

        const dataUrl = await AssetEditorHelper.convertBlobToBase64(blob); // Convert the blob to base64.
        imageElement.src = dataUrl; // Set the image src to the resized image.

        return imageElement;
    };
}

export default ImageExportHelper;
