import React, { useEffect } from 'react';
import Translation from 'components/data/Translation';
import AssetEditorControllerStepper from 'components/assets/AssetEditor/components/AssetEditorControllerStepper';
import useAssetEditorStepperHook from 'components/assets/AssetEditor/hooks/asset-editor-stepper-hook';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import AssetEditorState from 'components/assets/AssetEditor/interfaces/AssetEditorState';
import AssetEditorReviewStep from 'components/assets/AssetEditor/components/AssetEditorReviewStep';
import StepperLoading from 'components/assets/AssetEditor/components/AssetEditorControllerStepper/components/stepper-loading';
import backgroundRemoverSteps from './data/background-remover-steps';
import useAssetEditorControllersHook from '../../hooks/use-asset-editor-controllers-hook';

/**
 * The `AssetBackgroundRemoverController` component is responsible handling user interactions of the asset background remover feature.
 */
const AssetBackgroundRemoverController: React.FC = () => {
    const { stepperStep } = useAssetEditorStepperHook(); // Hook to manage the asset editor stepper.
    const { onApply, onCancel, abortController, runAssetEditorImageProcess } = useAssetEditorControllersHook();

    const { loading, componentKey } = useComponentStore<AssetEditorState>('AssetEditor', {
        fields: { loading: 'loading', componentKey: 'componentKey' }
    }); // Hook to get the asset editor store.

    useEffect(() => {
        if (componentKey === 'backgroundRemover') removeBackground();
    }, [componentKey]);

    const onBackgroundRemoverApply = () => {
        ComponentStoreHelpers.setModel('AssetEditor', 'backgroundRemoverState.isBackgroundRemoved', true); // Set isBackgroundRemoved to true
        onApply();
    };

    /* Runs the background remover on the asset. */
    const removeBackground = () => {
        runAssetEditorImageProcess('backgroundRemover', backgroundRemoverSteps.length, abortController, {}); // Remove the background from the image.
    };

    return (
        <>
            <AssetEditorControllerStepper
                steps={backgroundRemoverSteps}
                isLoading={loading}
                loadingChildren={
                    <StepperLoading onCancelClick={onCancel} text={Translation.get('assetGalleryDialog.assetEditor.removingBackground', 'content-space')} />
                }>
                {stepperStep === 0 && <AssetEditorReviewStep onCancel={onCancel} onApply={onBackgroundRemoverApply} />}
            </AssetEditorControllerStepper>
        </>
    );
};

export default AssetBackgroundRemoverController;
