import { StoreTypes } from 'types/store.type';
import { Collection } from 'types/collection.type';
import { Filters } from 'types/filter.type';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import { SortDirection, SortField } from '../types/asset-management.service.type';

interface CollectionsListState {
    stateList: Collection[] | null;
    stateNextPageToken: number;
    stateFilters: Filters;
    stateSortField?: SortField;
    stateSortDirection?: SortDirection;
}

const getCollectionsListState = (storeName: StoreTypes): CollectionsListState => {
    const { collectionsList, collectionsNextPageToken, state } = ComponentStoreHelpers.get(storeName);
    const { collectionSortField, collectionSortDirection, collectionFilters } = state;

    return {
        stateList: collectionsList,
        stateNextPageToken: collectionsNextPageToken,
        stateFilters: collectionFilters,
        stateSortField: collectionSortField,
        stateSortDirection: collectionSortDirection
    };
};

export { getCollectionsListState };
