import React from 'react';
import Translation from 'components/data/Translation';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import AssetEditorImage from '../AssetEditor/components/AssetEditorImage';
import OverlayLoadingIndicatorWrapper from '../AssetEditor/components/OverlayLoadingIndicator';
import AssetEditorState from '../AssetEditor/interfaces/AssetEditorState';
import AssetEditorHelper from '../AssetEditor/helpers/asset-editor-helper';

/**
 * Renders the changes made to the asset by the background remover.
 */
const AssetEditorPreview: React.FC = () => {
    const { originalAssetSrc, modifiedAssetSrc, previewAssetSrc, loading, componentKey } = useComponentStore<AssetEditorState>('AssetEditor', {
        fields: {
            originalAssetSrc: 'originalAssetSrc',
            modifiedAssetSrc: 'modifiedAssetSrc',
            previewAssetSrc: 'previewAssetSrc',
            loading: 'loading',
            componentKey: 'componentKey'
        }
    });

    const assetSrc = AssetEditorHelper.getAssetUrl(originalAssetSrc, modifiedAssetSrc, previewAssetSrc); // Get the current asset url.

    const getLoaderText = () => {
        switch (componentKey) {
            case 'backgroundRemover':
                return Translation.get('assetGalleryDialog.assetEditor.removingBackground', 'content-space');
            case 'imageCompressor':
                return Translation.get('assetGalleryDialog.assetEditor.compressing', 'content-space');
            default:
                return Translation.get('assetGalleryDialog.assetEditor.loading', 'content-space');
        }
    };

    return (
        <OverlayLoadingIndicatorWrapper text={getLoaderText()} isLoading={loading}>
            <AssetEditorImage imageSrc={assetSrc} imageBoxStyle={{ transform: `rotate(0deg) scaleX(1) scaleY(1)` }} />
        </OverlayLoadingIndicatorWrapper>
    );
};

export default AssetEditorPreview;
