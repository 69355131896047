import React, { useEffect } from 'react';
import Icon from 'components/ui-components-v2/Icon';
import Button from 'components/ui-components-v2/Button';
import AssetEditorControllerStepper from 'components/assets/AssetEditor/components/AssetEditorControllerStepper';
import useAssetEditorStepperHook from 'components/assets/AssetEditor/hooks/asset-editor-stepper-hook';
import AssetEditorActionRow from 'components/assets/AssetEditor/components/AssetEditorActionsRow/asset-editor-actions';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import AssetEditorState from 'components/assets/AssetEditor/interfaces/AssetEditorState';
import Translation from 'components/data/Translation';
import AssetEditorReviewStep from 'components/assets/AssetEditor/components/AssetEditorReviewStep';
import StepperLoading from 'components/assets/AssetEditor/components/AssetEditorControllerStepper/components/stepper-loading';
import AssetMaskEditorState from 'components/assets/AssetMaskEditor/interface/mask-editor-state';
import AssetMaskEditorMode from 'components/assets/AssetMaskEditor/components/asset-mask-editor-mode';
import objectRemoverSteps from './data/magic-eraser-steps';
import useAssetEditorControllersHook from '../../hooks/use-asset-editor-controllers-hook';

/**
 * The `Asset objectRemoverController` component is responsible handling user interactions
 * for the asset Object Remover feature. This component uses the `useAssetEditorStepperHook` and `useComponentStore`
 * hooks to manage the state of the asset editor and the Object Remover feature.
 */
const AssetObjectRemoverController: React.FC = () => {
    const { stepperStep } = useAssetEditorStepperHook(); // Hook to manage the asset editor stepper.
    const { onApply, onCancel, abortController, runAssetEditorImageProcess, onClear, onEdit } = useAssetEditorControllersHook();

    const { loading } = useComponentStore<AssetEditorState>('AssetEditor', { fields: { loading: 'loading' } }); // Hook to get the asset editor store.

    const { isMaskEdited, maskAssetSrc, isEditTriggered, isMaskAssetSrcReady } = useComponentStore<AssetMaskEditorState>('AssetMaskEditor', {
        fields: { isMaskEdited: 'isMaskEdited', maskAssetSrc: 'maskAssetSrc', isEditTriggered: 'isEditTriggered', isMaskAssetSrcReady: 'isMaskAssetSrcReady' }
    }); // Hook to manage the Object Remover state.

    /** Objects will be removed when there is a mask asset source and the erase trigger state is true. */
    useEffect(() => {
        // Check if maskAssetSrc has changed from the previous value
        if (isMaskAssetSrcReady && maskAssetSrc && isEditTriggered) removeSelectedObjectsFromImage(); // Trigger function to remove objects from image
    }, [isMaskAssetSrcReady]);

    /** Remove objects of an image based on the mask that the user has created. */
    const removeSelectedObjectsFromImage = () => {
        runAssetEditorImageProcess('objectRemover', objectRemoverSteps.length, abortController, {});
    };

    return (
        <>
            <AssetEditorControllerStepper
                steps={objectRemoverSteps}
                isLoading={loading}
                loadingChildren={<StepperLoading onCancelClick={onCancel} text={'Removing objects...'} />}>
                {stepperStep === 0 && (
                    <div>
                        <AssetMaskEditorMode />
                        <AssetEditorActionRow>
                            <Button disabled={!isMaskEdited} onClick={onClear} variant="text">
                                {Translation.get('actions.reset', 'common')}
                            </Button>
                            <Button disabled={!isMaskEdited} startIcon={<Icon>auto_awesome</Icon>} onClick={onEdit} variant="contained" color="primary">
                                {Translation.get('assetGalleryDialog.assetEditor.erase', 'content-space')}
                            </Button>
                        </AssetEditorActionRow>
                    </div>
                )}
                {stepperStep === 1 && <AssetEditorReviewStep onCancel={onCancel} onApply={onApply} />}
            </AssetEditorControllerStepper>
        </>
    );
};

export default AssetObjectRemoverController;
