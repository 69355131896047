import React, { useEffect, useState } from 'react';
import Icon from 'components/ui-components-v2/Icon';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import TextField from 'components/ui-components-v2/TextField';
import Button from 'components/ui-components-v2/Button';
import AssetEditorControllerStepper from 'components/assets/AssetEditor/components/AssetEditorControllerStepper';
import useAssetEditorStepperHook from 'components/assets/AssetEditor/hooks/asset-editor-stepper-hook';
import AssetEditorActionRow from 'components/assets/AssetEditor/components/AssetEditorActionsRow/asset-editor-actions';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import StepperLoading from 'components/assets/AssetEditor/components/AssetEditorControllerStepper/components/stepper-loading';
import AssetEditorState from 'components/assets/AssetEditor/interfaces/AssetEditorState';
import Translation from 'components/data/Translation';
import AssetSelector from 'components/input/AssetSelector';
import AssetEditorReviewStep from 'components/assets/AssetEditor/components/AssetEditorReviewStep';
import AssetMaskEditorState from 'components/assets/AssetMaskEditor/interface/mask-editor-state';
import AssetMaskEditorMode from 'components/assets/AssetMaskEditor/components/asset-mask-editor-mode';
import magicEditorSteps from './data/magic-editor-steps';
import useAssetEditorControllersHook from '../../hooks/use-asset-editor-controllers-hook';

import './styles/main.scss';

type InputType = 'text' | 'image';

/**
 * The `AssetMaskEditorController` component is responsible handling user interactions
 * for the asset magic editor feature. This component uses the `useAssetEditorStepperHook` and `useComponentStore`
 * hooks to manage the state of the asset editor and the magic editor feature.
 */
const AssetMagicEditorController: React.FC = () => {
    const { stepperStep, handleNext, handleBack } = useAssetEditorStepperHook(); // Hook to manage the asset editor stepper.
    const [prompt, setPrompt] = useState('');
    const [inputType, setInputType] = useState<InputType>('text');
    const [example, setExample] = useState('');
    const { onApply, onCancel, abortController, runAssetEditorImageProcess, onClear, onEdit } = useAssetEditorControllersHook();

    const { loading } = useComponentStore<AssetEditorState>('AssetEditor', {
        fields: { loading: 'loading' }
    }); // Hook to manage the asset editor state.

    const { isMaskEdited, maskAssetSrc, isEditTriggered, isMaskAssetSrcReady } = useComponentStore<AssetMaskEditorState>('AssetMaskEditor', {
        fields: {
            activeTool: 'activeTool',
            isMaskEdited: 'isMaskEdited',
            maskAssetSrc: 'maskAssetSrc',
            isEditTriggered: 'isEditTriggered',
            isMaskAssetSrcReady: 'isMaskAssetSrcReady'
        }
    }); // Hook to manage the mask editor state.

    /** Remove objects of an image based on the mask that the user has created. */
    const inpaint = () => {
        if (inputType === 'text') runAssetEditorImageProcess('inpaint', magicEditorSteps.length, abortController, { prompt });
        if (inputType === 'image') {
            runAssetEditorImageProcess('paintByExample', magicEditorSteps.length, abortController, { exampleFileUrl: example });
        }
    };

    /** Objects will be removed when there is a mask asset source and the erase trigger state is true. */
    useEffect(() => {
        if (isMaskAssetSrcReady && maskAssetSrc && isEditTriggered) inpaint(); // Trigger function to inpaint the masked region
    }, [isMaskAssetSrcReady]);

    const handleExampleChange = (data) => {
        setExample(data[0].url);
    };

    const handleSwitchInputType = (_event: React.MouseEvent<HTMLElement>, inputType: InputType) => {
        setInputType(inputType);
    };

    const evaluateDisabled = () => {
        if (inputType === 'text') {
            return !prompt;
        }
        return !example;
    };

    return (
        <>
            <AssetEditorControllerStepper
                steps={magicEditorSteps}
                isLoading={loading}
                loadingChildren={<StepperLoading onCancelClick={onCancel} text={'Generating...'} />}>
                {stepperStep === 0 && (
                    <div>
                        <AssetMaskEditorMode />
                        <AssetEditorActionRow>
                            <Button disabled={!isMaskEdited} onClick={onClear} variant="text">
                                {Translation.get('actions.reset', 'common')}
                            </Button>
                            <Button disabled={!isMaskEdited} onClick={handleNext} variant="contained" color="primary">
                                {Translation.get('actions.next', 'common')}
                            </Button>
                        </AssetEditorActionRow>
                    </div>
                )}
                {stepperStep === 1 && (
                    <div className="asset-magic-editor__stepper__inputs">
                        <ToggleButtonGroup className="select-mode__toggle-btn-group" value={inputType} exclusive onChange={handleSwitchInputType}>
                            <ToggleButton
                                classes={{ root: 'toggle-btn-root', selected: 'toggle-btn-selected' }}
                                className="select-mode__toggle-btn-group__btn"
                                value={'text'}>
                                {Translation.get('labels.prompt', 'common')}
                            </ToggleButton>
                            <ToggleButton
                                classes={{ root: 'toggle-btn-root', selected: 'toggle-btn-selected' }}
                                className="select-mode__toggle-btn-group__btn"
                                value={'image'}>
                                {Translation.get('labels.image', 'common')}
                            </ToggleButton>
                        </ToggleButtonGroup>
                        {inputType === 'text' && (
                            <TextField
                                className="asset-magic-editor__prompt-input"
                                value={prompt}
                                onChange={(event) => setPrompt(event.target.value)}
                                multiline
                                rows={4}
                                placeholder={Translation.get('assetGalleryDialog.assetEditor.promptPlaceHolder', 'content-space')}
                            />
                        )}
                        {inputType === 'image' && <AssetSelector dark fileType={'image'} selectType={'single'} canUpload onMutation={handleExampleChange} />}
                        <AssetEditorActionRow>
                            <Button onClick={handleBack} variant="text">
                                {Translation.get('labels.back', 'common')}
                            </Button>
                            <Button disabled={evaluateDisabled()} startIcon={<Icon>auto_awesome</Icon>} onClick={onEdit} variant="contained" color="primary">
                                {Translation.get('assetGalleryDialog.assetEditor.generate', 'content-space')}
                            </Button>
                        </AssetEditorActionRow>
                    </div>
                )}
                {stepperStep === 2 && <AssetEditorReviewStep onCancel={onCancel} onApply={onApply} />}
            </AssetEditorControllerStepper>
        </>
    );
};

export default AssetMagicEditorController;
