import AssetEditorStepper from 'components/assets/AssetEditor/interfaces/AssetEditorStepper';
import Translation from 'components/data/Translation';

/**
 * An array of objects representing the steps in the image compression process.
 * Each object contains a title and description for the step.
 */
const imageCompressorSteps: AssetEditorStepper[] = [
    {
        title: Translation.get('assetGalleryDialog.assetEditor.outputSettings', 'content-space'),
        description: Translation.get('assetGalleryDialog.assetEditor.compressImageDescription', 'content-space')
    },
    {
        title: Translation.get('assetGalleryDialog.assetEditor.reviewResults', 'content-space'),
        description: ''
    }
];

export default imageCompressorSteps;
