import { FilterSetup, FilterOption } from 'types/filter.type';
import Translation from 'components/data/Translation';
import { convertColorNameToHex, shouldTextBeLightOnBackgroundColor } from 'helpers/colors';
import { UNASSIGNED_KEY } from 'components/ui-base/GenericFilter/constants';
import { AssetField } from '../types/asset-management-field.type';
import { ASSET_ROOT_INPUTS } from '../constants';

// Derive the filter type from the field type
const getFilterType = (fieldType: 'select' | 'selectMultiple' | 'selectMultipleSubs'): 'selectMultiple' | 'selectMultipleSubs' => {
    if (fieldType === 'select') return 'selectMultiple';
    return fieldType;
};

// Derive the filter options from the field items
const getFilterOptions = (field: AssetField) => {
    const options: FilterOption[] = [];
    if (field.type === 'selectMultipleSubs') {
        field.items?.forEach((item) => {
            if (item.value !== '_all') {
                const subOptions: FilterOption['subOptions'] = [];
                item.subitems?.forEach((subitem) => {
                    subOptions.push({ key: `${item.value}.${subitem.value}`, value: subitem.label });
                });
                options.push({ key: item.value, value: item.label, subOptions });
            }
        });
    } else {
        field.items?.forEach((item) => {
            if (item.value !== '_all') {
                const option: FilterOption = { key: item.value, value: item.label };
                if (field.key === 'dominant_colors') {
                    option.colors = {
                        color: shouldTextBeLightOnBackgroundColor(convertColorNameToHex(item.value)) ? 'white' : 'black',
                        backgroundColor: item.value
                    };
                }
                options.push({ ...option });
            }
        });
    }
    if (!ASSET_ROOT_INPUTS.includes(field.key) && options.length) {
        options.push({ key: UNASSIGNED_KEY, value: Translation.get('actions.unassigned', 'common') });
    }
    return options;
};

// Build the filter setup for Assets
const getAssetFilterSetup = (assetFields: AssetField[], order?: string[]): FilterSetup => {
    const filterSetup: FilterSetup = assetFields
        // Only use filterable fields
        .filter((field) => field.filterable === true)
        // Only use field types that can be filtered
        .filter((field) => ['select', 'selectMultiple', 'selectMultipleSubs'].includes(field.type))
        .map((field) => {
            return {
                label: field.name,
                name: field.key,
                type: getFilterType(field.type as 'select' | 'selectMultiple' | 'selectMultipleSubs'),
                options: field.items ? getFilterOptions(field) : []
            };
        });

    // Check if we should add a publicly available filter
    const publicField = assetFields.find((field) => field.key === 'public');
    if (publicField?.filterable) {
        filterSetup.push({
            label: publicField.name,
            name: publicField.key,
            type: 'selectMultiple',
            options: [
                { key: 'public', value: Translation.get('labels.public', 'asset-management') },
                { key: 'private', value: Translation.get('labels.private', 'asset-management') }
            ]
        });
    }

    if (order) {
        filterSetup.sort((a, b) => {
            const aIndex = order.indexOf(a.name);
            const bIndex = order.indexOf(b.name);
            if (aIndex === -1) return 1; // Move items not present in order to the bottom
            if (bIndex === -1) return -1; // Move items not present in order to the bottom
            return aIndex - bIndex;
        });
    }
    return filterSetup.filter((filter) => filter.options.length > 0);
};

export { getAssetFilterSetup };
