import Translation from 'components/data/Translation';
import { ToggleButtonProps } from 'components/ui-components/ToggleButtonGroup';
import { CompressorMode } from '../interfaces/image-compressor-state';

interface CompressorToggleButtonProps extends ToggleButtonProps {
    value: CompressorMode;
}

/**
 * An array of toggle buttons used in the image compressor component.
 */
const compressorToggleButtons: CompressorToggleButtonProps[] = [
    {
        text: Translation.get('assetGalleryDialog.imageCompressor.best', 'content-space'),
        value: 'best',
        icon: ''
    },
    {
        text: Translation.get('assetGalleryDialog.imageCompressor.custom', 'content-space'),
        value: 'custom',
        icon: ''
    },
    {
        text: Translation.get('assetGalleryDialog.imageCompressor.none', 'content-space'),
        value: 'none',
        icon: ''
    }
];

export default compressorToggleButtons;
