import { CollectionTreeChild } from 'types/collection.type';
import { AssetType } from 'types/asset.type';
import Setup from 'components/data/Setup';
import User from 'components/data/User';
import Translation from 'components/data/Translation';
import { AssetField, AssetFieldOption } from 'components/asset-management/types/asset-management-field.type';
import { convertColorNameToHex } from 'helpers/colors';
import { MULTIS } from '../constants';
import { getCollectionOptionsFromTree } from './getCollectionOptionsFromTree';
import AssetManagementService from '../services/asset-management.service';

const getMultiField = (field: AssetField): AssetField => {
    const items: Record<string, string> = Setup.get(field.key) || {};
    const options: AssetFieldOption[] = [];
    const hasMultiRight = User.hasRight(MULTIS[field.key]);
    const userOptions = User.get(field.key) ? User.get(field.key) : [];

    if (hasMultiRight) {
        options.push({ value: '_all', label: `${Translation.get('labels.selectAll', 'common')} ${field.key}` });
    }
    Object.entries(items).forEach(([key, value]) => {
        if (hasMultiRight || userOptions.includes(key)) options.push({ value: key, label: value });
    });
    const newField = {
        ...field,
        items: options
    };
    return newField;
};

const getBackEndField = async (type: AssetType, field: AssetField): Promise<AssetField> => {
    const options = await AssetManagementService.getLabelOptions(type, field.key);
    const items = options.map((option) => ({ value: option, label: field.key === 'dominant_colors' ? convertColorNameToHex(option) : option }));
    return {
        ...field,
        items
    };
};

const getSetupField = (field: AssetField): AssetField => {
    const optionsFromSetup: Record<string, string> = Setup.get(field.optionsFromSetup);
    const options: AssetFieldOption[] = [];
    if (optionsFromSetup) {
        if (field.type === 'selectMultiple') {
            options.push({ value: '_all', label: `${Translation.get('labels.selectAll', 'common')}` });
        }
        Object.entries(optionsFromSetup).forEach(([key, value]) => {
            options.push({ value: key, label: value });
        });
    }
    const newField = {
        ...field,
        items: options
    };
    return newField;
};

const getRootField = (field: AssetField, items: AssetFieldOption[]): AssetField => {
    return {
        ...field,
        items
    };
};

const getField = async (
    field: AssetField,
    collectionsTree: CollectionTreeChild[],
    subTypesItems: AssetFieldOption[],
    type: AssetType
): Promise<AssetField | null> => {
    if (MULTIS[field.key]) {
        // Handle reserved key for special multi field
        if (!Setup.hasModule(MULTIS[field.key])) return null;
        const multiInput: AssetField = getMultiField(field);
        return multiInput;
    } else if (field.optionsFromSetup) {
        // Handle field that gets its options from setup
        const setupInput: AssetField = getSetupField(field);
        return setupInput;
    } else if (field.optionsFromBackEnd) {
        // Handle a field that gets its options from the backend.
        const backendInput: AssetField = await getBackEndField(type, field);
        return backendInput;
    } else if (field.key === 'collections') {
        // Handle collections field that gets its options from teh collections tree in Redux.
        const collectionsList = getCollectionOptionsFromTree(collectionsTree);
        const collectionsInput: AssetField = getRootField(field, collectionsList);
        return collectionsInput;
    } else if (field.key === 'subType') {
        // Handle subtype field that gets its options from the subtypeIcons keys in config.
        const subTypeInput: AssetField = getRootField(field, subTypesItems);
        return subTypeInput;
    } else if (field.type === 'selectMultiple') {
        // We want to give all selectMultiples the select all feature.
        const items = field.items ? [{ value: '_all', label: `${Translation.get('labels.selectAll', 'common')}` }, ...field.items] : [];
        return { ...field, items };
    } else {
        return field;
    }
};

const getAssetFieldsWithOptions = async (
    assetFields: AssetField[],
    collectionsTree: CollectionTreeChild[],
    subTypesItems: AssetFieldOption[],
    type: AssetType
): Promise<AssetField[]> => {
    const assetFieldsWithOptions: AssetField[] = [];

    await Promise.all(
        assetFields.map(async (field: AssetField) => {
            const fieldWithOptions = await getField(field, collectionsTree, subTypesItems, type);
            if (fieldWithOptions) assetFieldsWithOptions.push(fieldWithOptions);
        })
    );

    return assetFieldsWithOptions;
};

export { getAssetFieldsWithOptions };
