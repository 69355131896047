import { AssetV2 } from 'types/asset.type';
import { Collection } from 'types/collection.type';

// This function checks if an item has labels and if any of them has content.
const hasLabels = (item: Collection | AssetV2<unknown, unknown>): boolean => {
    if (!item) return false;
    if (!item.labels) return false;
    return Object.values(item.labels).some((label) => label.length > 0);
};

export { hasLabels };
