import { StoreTypes } from 'types/store.type';
import ComponentStoreHelpers from 'components/data/ComponentStore';

// Reset a certain assetList in Redux.
const resetList = (storeName: StoreTypes, rootModel: string): void => {
    const modelsToSet = (() => {
        if (rootModel.length) {
            return [[`${rootModel}assetsList`, null]];
        } else {
            return [
                ['assetsList', null],
                ['assetsNextPageToken', 0]
            ];
        }
    })();

    // Setting the assetsList to null if we want to refresh the list, the frontend now shows a load spinner.
    ComponentStoreHelpers.setMultiModels(storeName, modelsToSet);
};

export { resetList };
