import { Filter, FilterOption, FilterSetup, Filters } from 'types/filter.type';
import { UNASSIGNED_KEY } from 'components/ui-base/GenericFilter/constants';
import { AssetFilterCounts } from '../types/asset-management.service.type';
import { COLLECTIONS_KEY } from '../constants';

// Add the current asset counts to the filter setup.
const getCurrentFilterSetup = (filterSetup: FilterSetup, counts: AssetFilterCounts = {}, filters: Filters, hideFilters?: string[]): FilterSetup => {
    // We want to know is an option is relevant to show. We hide it if we know there would be no results and it wasn't selected by the user.
    const isOptionNeeded = (filter: Filter, option: FilterOption): boolean => {
        if (option.count === undefined) return true;
        if (option.count > 0) return true;
        if (filter.name === COLLECTIONS_KEY) return true;
        if (filters && filter.name in filters && filters[filter.name].includes(option.key)) return true;
        return false;
    };

    // We want to know if showing the filter is relevant. We hide it if we know there are no relevant options and it wasn't selected by the user.
    const isFiterNeeded = (filter: Filter): boolean => {
        if (hideFilters && hideFilters.length > 0 && hideFilters.includes(filter.name)) return false;
        if (filters && filter.name in filters) return true;
        if (filter.options.length > 1) return true;
        if (filter.options.length === 1 && filter.options[0].key !== UNASSIGNED_KEY) return true;
        return false;
    };

    const currentFilterSetup = filterSetup
        .map((filter) => {
            if (filter.type === 'selectMultipleSubs') {
                return {
                    ...filter,
                    options: filter.options
                        .map((option) => {
                            if (option.key === UNASSIGNED_KEY) return option;
                            let count = 0;
                            const subOptions = option.subOptions
                                ?.map((subOption) => {
                                    const subCount = counts[filter.name]?.[subOption.key] || 0;
                                    count += subCount;
                                    return { ...subOption, count: subCount };
                                })
                                .filter((option) => isOptionNeeded(filter, option)) // leave out non-relevant options
                                .sort((a, b) => b.count - a.count); // sort options by count in descending order;
                            return { ...option, subOptions, count };
                        })
                        .filter((option) => isOptionNeeded(filter, option)) // leave out non-relevant options
                        .sort((a, b) => (b.count || 0) - (a.count || 0)) // sort options by count in descending order
                };
            } else {
                return {
                    ...filter,
                    options: filter.options
                        .map((option) => {
                            const count = (() => {
                                if (filter.name === COLLECTIONS_KEY) return undefined;
                                if (option.key === UNASSIGNED_KEY) return undefined;
                                return counts[filter.name]?.[option.key] || 0;
                            })();
                            return { ...option, count };
                        })
                        .filter((option) => isOptionNeeded(filter, option)) // leave out non-relevant options
                        .sort((a, b) => (b.count || 0) - (a.count || 0)) // sort options by count in descending order, handling undefined counts
                };
            }
        })
        .filter((filter) => isFiterNeeded(filter)); // leave out non-relevant filters

    return currentFilterSetup;
};

export { getCurrentFilterSetup };
