import AssetEditorStepper from 'components/assets/AssetEditor/interfaces/AssetEditorStepper';
import Translation from 'components/data/Translation';

const magicEditorSteps: AssetEditorStepper[] = [
    {
        title: Translation.get('assetGalleryDialog.assetEditor.makeSelection', 'content-space'),
        description: Translation.get('assetGalleryDialog.assetEditor.makeSelectionDescription', 'content-space')
    },
    {
        title: Translation.get('assetGalleryDialog.assetEditor.promptTitle', 'content-space'),
        description: Translation.get('assetGalleryDialog.assetEditor.promptDescription', 'content-space')
    },
    {
        title: Translation.get('assetGalleryDialog.assetEditor.reviewResults', 'content-space'),
        description: Translation.get('assetGalleryDialog.assetEditor.reviewResultDescription', 'content-space')
    }
];

export default magicEditorSteps;
