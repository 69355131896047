import Translation from 'components/data/Translation';
import { AssetManagementConfig } from '../types/asset-management-config.type';
import { getLanguageNameSpace } from './getLanguageNameSpace';

const getSubTypeItems = (config: AssetManagementConfig) => {
    const { subtypeIcons, type } = config;

    const languageNameSpace = getLanguageNameSpace(type);

    const subTypeItems = Object.keys(subtypeIcons).map((key) => ({ value: key, label: Translation.get(`sub_types.${key}`, languageNameSpace) }));

    return subTypeItems;
};

export { getSubTypeItems };
