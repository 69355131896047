import { StoreTypes } from 'types/store.type';
import { AssetStatus, AssetV2 } from 'types/asset.type';
import { Filters } from 'types/filter.type';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import { SortDirection, SortField } from '../types/asset-management.service.type';

interface ListState {
    stateList: AssetV2<unknown, unknown>[] | null;
    stateNextPageToken: number;
    stateFilters: Filters;
    stateSortField?: SortField;
    stateSortDirection?: SortDirection;
}

const EMPTY_LIST_STATE: ListState = { stateList: null, stateNextPageToken: 0, stateFilters: {}, stateSortField: undefined, stateSortDirection: undefined };

const getListState = (storeName: StoreTypes, status: AssetStatus, collectionId?: string): ListState => {
    const { binned, draft, collectionsContent, assetsList, assetsNextPageToken, assetFilters, assetSortField, assetSortDirection } =
        ComponentStoreHelpers.get(storeName);

    if (status === 'binned') {
        if (!binned) return EMPTY_LIST_STATE;
        return {
            stateList: binned.assetsList,
            stateNextPageToken: binned.assetsNextPageToken,
            stateFilters: binned.assetFilters,
            stateSortField: binned.assetSortField,
            stateSortDirection: binned.assetSortDirection
        };
    }

    if (status === 'draft') {
        if (!draft) return EMPTY_LIST_STATE;
        return {
            stateList: draft.assetsList,
            stateNextPageToken: draft.assetsNextPageToken,
            stateFilters: draft.assetFilters,
            stateSortField: draft.assetSortField,
            stateSortDirection: draft.assetSortDirection
        };
    }

    if (collectionId) {
        if (!collectionsContent[collectionId]) return EMPTY_LIST_STATE;
        return {
            stateList: collectionsContent[collectionId].assetsList,
            stateNextPageToken: collectionsContent[collectionId].assetsNextPageToken,
            stateFilters: collectionsContent[collectionId].assetFilters,
            stateSortField: collectionsContent[collectionId].assetSortField,
            stateSortDirection: collectionsContent[collectionId].assetSortDirection
        };
    }

    return {
        stateList: assetsList,
        stateNextPageToken: assetsNextPageToken,
        stateFilters: assetFilters,
        stateSortField: assetSortField,
        stateSortDirection: assetSortDirection
    };
};

export { getListState };
